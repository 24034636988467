import React from "react"
import Image from "next/image"
import { useTranslation } from "next-i18next"
import tw from "twin.macro"

import { Else, If, Then } from "@components/If"
import Container from "@components/v2/Container"
import { useTheme } from "@emotion/react"
import Space from "@layouts/Space"

import MockStockDark from "../images/mockup-stock-dark.webp"
import MockStockLight from "../images/mockup-stock-light.webp"
import MockTrade from "../images/mockup-trade.webp"

const StyledContainer = tw(Container)`
    flex flex-col 
    items-center 
    xl:py-10 py-8
    xl:gap-[80px] gap-[62px] 
`

const Wrapper = tw.div`
    w-full
    flex xl:flex-row
    justify-around
    xl:gap-[120px]
`

const WrapperText = tw(Space)`
    xl:!gap-3
    xl:items-start items-center
    !w-full
`

const Title = tw.h1`
    xl:heading-4 heading-6
    text-bold dark:text-dark-bold
`

const Desc = tw.span`
    xl:paragraph-2 paragraph-3
    xl:text-left text-center
`

const Feature = () => {
    const { t } = useTranslation("home")

    const isDarkMode = useTheme().mode === "dark"
    return (
        <StyledContainer>
            <Wrapper className='flex-col'>
                <div className='w-full'>
                    <Image src={MockTrade} width={580} height={480} layout='responsive' alt='mock-trade' />
                </div>
                <WrapperText align='center' justify='center' direction='vertical'>
                    <Title>{t("us_stock.feature.1.title")}</Title>
                    <Desc>{t("us_stock.feature.1.desc")}</Desc>
                </WrapperText>
            </Wrapper>
            <Wrapper className='flex-col-reverse'>
                <WrapperText align='center' justify='center' direction='vertical'>
                    <Title>{t("us_stock.feature.2.title")}</Title>
                    <Desc>{t("us_stock.feature.2.desc")}</Desc>
                </WrapperText>
                <div className='w-full'>
                    <If condition={isDarkMode}>
                        <Then>
                            <Image
                                src={MockStockDark.src}
                                width={580}
                                height={480}
                                layout='responsive'
                                alt='mock-stock'
                            />
                        </Then>
                        <Else>
                            <Image
                                src={MockStockLight.src}
                                width={580}
                                height={480}
                                layout='responsive'
                                alt='mock-stock'
                            />
                        </Else>
                    </If>
                </div>
            </Wrapper>
        </StyledContainer>
    )
}

export default Feature
